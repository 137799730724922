import ViewListIcon from "@material-ui/icons/ViewList";
import React, { useState } from "react";
import { FormDataConsumer, FormTab, useTranslate, Button } from "react-admin";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm, Field } from "react-final-form";
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { CallDirectOptions } from '../../enum/ESetting';
import { FieldLabel } from "./SettingsStyle";

export const GeneralSettingKeys = {
  FOLDER_LIMIT_ROOMS: "FOLDER_LIMIT_ROOMS",
}

const initValidations = {
  [GeneralSettingKeys.FOLDER_LIMIT_ROOMS]: '',
};

export const GeneralSettings = props => {
  const translate = useTranslate();
  const form = useForm();
  const [openDialogCallOptions, setOpenDialogCallOptions] = useState(false);

  const [validations, setValidations] = useState(initValidations);

  const dialogActionCallOptions = () => {
    setOpenDialogCallOptions(false);
  };

  const changeCallOptions = (event) => {
    const selectedValue = event.target.checked;
    let optionChange;
    if (selectedValue) {
      optionChange = [...form.getState().values.CALL_DIRECT_VIA, event.target.name];
    } else {
      optionChange = form.getState().values.CALL_DIRECT_VIA?.filter(e => e !== event.target.name);
    }
    if (optionChange?.length === 0) {
      setOpenDialogCallOptions(true);
    } else {
      form.change("CALL_DIRECT_VIA", optionChange);
    }
  };

  const handleMessageErr = (value, field) => {
    if (
      !value?.length &&
      (field === GeneralSettingKeys.FOLDER_LIMIT_ROOMS)
    ) {
        return `resources.settings.validations.required`;
    }
    if (
      !(value >= 100 && value <= 300) &&
      field === GeneralSettingKeys.FOLDER_LIMIT_ROOMS
    ) {
        return `resources.settings.validations.range_rooms_in_folder`;
    }
    return '';
  };

  const handleChangeNumber = (e) => {
    let { name, value } = e.target;
    const isNumberPositive = /^\d*\.?\d+$/.test(value);
    if (!isNumberPositive) {
      onValueChange(name, form.getState().values.FOLDER_LIMIT_ROOMS);
    }
    onValueChange(name, value);
    setValidations({
      ...validations,
      [name]: handleMessageErr(value, name),
    });
    props.onChange(!!handleMessageErr(value, name));
  };

  const onValueChange = (name, valueInput) => {
    let value = valueInput?.replace(/^((0+(?=\d))|((?<=\d)0+(?=\.)))/, ''); // Removes leading zeros
    form.change(name, value);
  };

  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.general"
      icon={<ViewListIcon />}
    >
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <div>
              <div>
                <Field name="CALL_DIRECT_VIA">{()=>null}</Field>
                <FieldLabel>
                  {translate("resources.settings.fields.call_direct_via")}
                </FieldLabel>
              </div>
              <StyledFormGroup>
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.matrix_turn_server)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.matrix_turn_server}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.matrix_turn_server")}
                />
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.jitsi_meet)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.jitsi_meet}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.jitsi_meet")}
                />
              </StyledFormGroup>
              <Dialog
                onClose={dialogActionCallOptions}
                aria-labelledby="simple-dialog-title"
                open={openDialogCallOptions}
              >
                  <DialogTitle id="alert-dialog-title">{translate('resources.settings.validations.required_call_via_title')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {translate('resources.settings.validations.required_call_via_content')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={dialogActionCallOptions} label="Ok"></Button>
                  </DialogActions>
              </Dialog>
              <div>
                <Field name="FOLDER_LIMIT_ROOMS">{()=>null}</Field>
                <FieldLabel>
                  {translate("resources.settings.fields.chat_folder")}
                </FieldLabel>
                <StyledTextField
                  id="FOLDER_LIMIT_ROOMS"
                  label={translate("resources.settings.fields.limit_rooms_in_folder")}
                  name="FOLDER_LIMIT_ROOMS"
                  value={formData.FOLDER_LIMIT_ROOMS}
                  onChange={handleChangeNumber}
                  onClick={handleChangeNumber}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS]}
                  helperText={
                    validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS] ?
                      translate(validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS]) :
                      null
                  }
                />
              </div>
            </div>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
};

GeneralSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const StyledFormGroup = withStyles({
  root: {
    marginLeft: '20px',
  }
})(FormGroup);

const StyledFormControlLabel = withStyles({
  root: {
    marginBottom: '20px',
  },
})(FormControlLabel);

const StyledTextField = styled(TextField)({
  '&.MuiTextField-root': {
    marginBottom: '30px',
  },
});
